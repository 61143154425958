






















































































import { GameVersion } from "@/api"
import { Component, Vue, Watch } from "vue-property-decorator"
import { URLManager } from "@/services/Managers"

@Component({
  components: {
    GameVersionModal: () => import("@/components/GameVersionModal.vue")
  }
})
export default class GameVersionManagementInterface extends Vue {
  gameFiles: any = {}
  gameFileSearch = ""
  filter = null
  filterOn = ["id", "version"]
  currentPage = 1
  perPage = 10
  totalRows = 1
  loading = false

  get tableColumns() {
    return [{ key: "id" }, { key: "version" }, { key: "actions" }]
  }

  async getGameFilesArray() {
    try {
      const gameData = await GameVersion.getGameFiles()

      this.loading = true
      this.gameFiles = gameData.reverse() // reverse the array to show the newest version first
      this.totalRows = gameData.length
      this.loading = false
    } catch (e) {
      this.$bvToast.toast("Failed to get Game Files", {
        title: "Error",
        variant: "danger",
        autoHideDelay: 3000,
        solid: true
      })
      console.error("Error occured when getting game files", e)
    }
  }

  async mounted() {
    await this.getGameFilesArray()
  }

  onFiltered(filteredItems) {
    this.totalRows = filteredItems.length
    this.currentPage = 1
  }

  async deleteGameVersion(id: number) {
    this.loading = true
    try {
      await GameVersion.deleteGameFile(id)
      await this.getGameFilesArray()
      this.$bvToast.toast("Game file deleted successfully", {
        title: "Success",
        variant: "success",
        autoHideDelay: 3000,
        solid: true
      })
    } catch (e) {
      this.$bvToast.toast("Failed to delete", {
        title: "Error",
        variant: "danger",
        autoHideDelay: 3000,
        solid: true
      })
      console.error("Error occured when deleting game file", e)
    }
  }

  async resynchronize() {
    try {
      await GameVersion.resynchronize()
      await this.getGameFilesArray()
      this.$bvToast.toast("Game file resynchronized successfully", {
        title: "Success",
        variant: "success",
        autoHideDelay: 3000,
        solid: true
      })
    } catch (e) {
      this.$bvToast.toast("Failed to resynchronize", {
        title: "Error",
        variant: "danger",
        autoHideDelay: 3000,
        solid: true
      })
      console.error('Error occured when resynchronizing game files', e)
    }
  }
}
